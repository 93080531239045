import React from "react"
import Layout from "../components/Layout"
import Pagetitle from "../components/Pagetitle";

const BlogPage = () => (
  <Layout>
    <div>
      <Pagetitle>Blog</Pagetitle>
    </div>
  </Layout>
)

export default BlogPage;
